import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import ReskillSlice, { ReskillApi } from 'src/pages/Reskill_Flow/ReskillSlice';
import CareerHistorySlice, { CareersApi } from '../pages/CareerHistory/CareerHistorySlice';
import UpskillSlice, {UpskillApi} from '../pages/Upskill_Flow/UpskillSlice';
import MainLayoutSlice, {MainLayoutApi} from '../layouts/main/MainLayoutSlice';

const store = configureStore({
    reducer: {
        careerHistory: CareerHistorySlice,
        upskill:UpskillSlice,
        reskill:ReskillSlice,
        mainLayout: MainLayoutSlice,
        [CareersApi.reducerPath]: CareersApi.reducer,
        [UpskillApi.reducerPath]: UpskillApi.reducer,
        [MainLayoutApi.reducerPath]: MainLayoutApi.reducer,
        [ReskillApi.reducerPath]: ReskillApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            CareersApi.middleware,
            UpskillApi.middleware,
            MainLayoutApi.middleware,
            ReskillApi.middleware
        ),
})
setupListeners(store.dispatch)
export default store