// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter, useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider, useSelector } from 'react-redux';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'src/routes';
// theme
import ThemeProvider from 'src/theme';

// Css File
import 'src/utils/Style.css';

// components
import { ThemeSettings, SettingsProvider } from 'src/components/settings';
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer';
import { useDeviceData, useDeviceSelectors } from 'react-device-detect';
// Store
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { createTheme } from '@mui/material/styles';
import store from './redux/Store';
import { analytics, getCurrentPath } from './utils';

// ----------------------------------------------------------------------

export default function App() {
const [theme, setTheme] = useState({})
const states =  store.getState()
const  collegeId  = states.mainLayout.collegeDetails._id
const  {currentPage}  = states.mainLayout
const [CollegeId, setCollegeId] = useState('')
const getCollegeColors = (colors) => {
  setTheme({
    primary: colors.primary,
    secondary: colors.secondary,
  })
}
const getIp = async () => {
  const userIp = localStorage.getItem('userIp')
  if(!userIp){
      const res = await axios.get("https://api.ipify.org/?format=json");
      const IP = res.data.ip
      localStorage.setItem('userIp', JSON.stringify(IP))
    }
}
useEffect(() => {
  getIp()
}, [])

const deviceInfoAnalytics = () => {
  let platform = navigator?.userAgentData?.platform 
  const deviceType = selectors?.deviceType
  if(deviceType === "browser" && data?.os?.name ){
    platform = data?.os?.name
  }else{
    platform = deviceType
  }
  if(platform && collegeId){
    analytics({
      event: 'DEVICE_INFO',
      from: platform,
      collegeId
    })
  }
}
const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)
useEffect(() => {
  if(collegeId){
    setCollegeId(collegeId)
    deviceInfoAnalytics()
  }
}, [collegeId]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   getIp()
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser)
  //     // window.removeEventListener('unload', alertUser)
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // const alertUser = e => {
  //   // e.preventDefault()
  //   // e.returnValue = ''
  //   const id  = store.getState().mainLayout.collegeDetails._id
  //   const pathArray = window.location.pathname.split('/')
  //   const currentPath = pathArray[pathArray.length - 1]
  //   if(id){
  //     analytics({
  //       collegeId:id,
  //       event: 'PAGE_LEAVE',
  //       from: currentPath,
  //     }).then(()=> localStorage.removeItem('userIp'))
  //   }
  // }

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider themeOverride={theme}>
          <BrowserRouter>
            <ThemeProvider >
              <ThemeSettings>
                <MotionLazyContainer>
                  <Provider store={store} >
                    <Router getCollegeColors={getCollegeColors} />
                  </Provider>
                </MotionLazyContainer>
              </ThemeSettings>
            </ThemeProvider>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}
