export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg, xs }) {
  return {
    '@media (min-width:0px)': {
      fontSize: pxToRem(xs),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const primaryFont = 'Public Sans, sans-serif';
const secondaryFont = 'Barlow, sans-serif';

// ----------------------------------------------------------------------

const typography = {
  fontFamily: primaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontSize: 16,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(60),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ xs: 40,sm: 48, md: 56, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(28),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 40 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(28),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 28 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ xs:16,sm: 18, md: 20, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.3,
    fontSize: pxToRem(17),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs:12 ,sm: 14, md: 16, lg: 18 }),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 10, sm: 12, md: 14, lg: 14 }),
  },
  small:{
    lineHeight: 1.6,
    fontSize: pxToRem(12),
    fontWeight: 600,
    ...responsiveFontSizes({ xs: 8,sm: 10, md: 12, lg: 12 }),
  },
  body1: {
    lineHeight: 1.6,
    fontSize: pxToRem(16),
    fontWeight: 400,
    ...responsiveFontSizes({ xs: 12,sm: 14, md: 14, lg: 16 }),
  },
  links: {
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 12,sm: 14, md: 14, lg: 16 }),
  },
  body2: {
    lineHeight: 22 / 14,
    fontWeight: 400,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 16,sm: 18, md: 20, lg: 24 }),
  },
  capitalize: {
    fontWeight: 700,
    lineHeight: 1.7,
    fontSize: pxToRem(16),
    textTransform: 'uppercase',
    ...responsiveFontSizes({ xs: 12,sm: 12, md: 14, lg: 16 }),
  },
  capitalize2: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    textTransform: 'uppercase',
    ...responsiveFontSizes({ xs: 12,sm: 14, md: 16, lg: 18 }),
  },
  capitalizeSmall: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(14),
    textTransform: 'uppercase',
    ...responsiveFontSizes({ xs: 10,sm: 12, md: 14, lg: 14 }),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
    ...responsiveFontSizes({ xs: 10,sm: 12, md: 14, lg: 14 }),
  },
  bold: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 12,sm: 12, md: 14, lg: 16 }),
  },
  bold2: {
    fontWeight: 700,
    lineHeight: 1.3,
    fontSize: pxToRem(15),
    ...responsiveFontSizes({ xs: 10,sm: 12, md: 13, lg: 15 }),
  },
  error: {
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 10,sm: 12, md: 14, lg: 14 }),
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(16),
    textTransform: 'unset',
    ...responsiveFontSizes({ xs: 12,sm: 12, md: 14, lg: 16 }),
  },
  button2: {
    fontWeight: 700, 
    lineHeight: 24 / 14,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ xs: 14,sm: 16, md: 18, lg: 20 }),
  },
  medium: {
    fontWeight: 600, 
    lineHeight: 1.6,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 10,sm: 12, md: 14, lg: 14 }),
  }
};

export default typography;
