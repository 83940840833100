import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ModalComponent ({className, open, children, type})  {
    return (
        <Box className={`app-modal ${className} ${open ? 'open' : ''}`} sx={{background: (theme) => type === 'sidebar' && `${theme.palette.primary.main}`}}>
            {children}
        </Box>
    )}
  
ModalComponent.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    children: PropTypes.node,
    type: PropTypes.string
};